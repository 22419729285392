import { makeStore } from './core/store';
import makeApi from './make-api';
import defaultReducer from './core/reducer';
import shopifyReducer from './shopify/shopifyReducer';
import shopifyMiddleware from './shopify/shopifyMiddleware';
import platform from './platform';
import { autoInitializeOffers, onReady } from './core/utils';
import { authorizeShopifyCustomer } from './shopify/shopifyBootstrap';
import shopifyTrackingMiddleware from './shopify/shopifyTrackingMiddleware';
import { addExperimentsReducer } from './core/experiments';

export const store = makeStore(
  ...(platform?.shopify_selling_plans
    ? [addExperimentsReducer(shopifyReducer), shopifyMiddleware]
    : [addExperimentsReducer(defaultReducer)]),
  platform.shopify && shopifyTrackingMiddleware
);

export const offers = makeApi(store);

export const isReady = offers.isReady;
export const addOptinChangedCallback = offers.addOptinChangedCallback;
export const addTemplate = offers.addTemplate;
export const clear = offers.clear;
export const config = offers.config;
export const disableOptinChangedCallbacks = offers.disableOptinChangedCallbacks;
export const getOptins = offers.getOptins;
export const getProductsForPurchasePost = offers.getProductsForPurchasePost;
export const initialize = offers.initialize;
export const previewMode = offers.previewMode;
export const register = offers.register;
export const resolveSettings = offers.resolveSettings;
export const setAuthUrl = offers.setAuthUrl;
export const setEnvironment = offers.setEnvironment;
export const setLocale = offers.setLocale;
export const setMerchantId = offers.setMerchantId;
export const setPublicPath = offers.setPublicPath;
export const setTemplates = offers.setTemplates;
export const setupCart = offers.setupCart;
export const setupProduct = offers.setupProduct;
export const setupProducts = offers.setupProducts;
export const autoInit = () => autoInitializeOffers(offers);

export { platform };
export default offers.initialize;
/*
 * Attempts to auto initialize the offer library reading the merchantId and env from
 * integration script i.e. <script src="http://static.ordergroove...."/>.
 * Useful when local develop using http redirects
 */
if (process.env.NODE_ENV === 'development') {
  console.info('%c Ordergroove Offers DEV MODE ', 'background: #222; color: #bada55');
  onReady(autoInit);
}

if (platform?.shopify_selling_plans) {
  onReady(() => authorizeShopifyCustomer(offers));
}
