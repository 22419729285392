import { createSessionId, fetchAuth } from './actions';
import {
  CREATED_SESSION_ID,
  SET_ENVIRONMENT_LOCAL,
  SET_ENVIRONMENT_DEV,
  SET_ENVIRONMENT_PROD,
  SET_ENVIRONMENT_STAGING,
  SET_MERCHANT_ID
} from './constants';
import { listenLocalStorageChanges } from './localStorage';

const waitFor = () => {
  let resolve, reject;
  return [
    new Promise((yes, no) => {
      resolve = yes;
      reject = no;
    }),
    resolve,
    reject
  ];
};
/**
 * Middleware that awaits offers being initialized and resumes the regular actions after.
 * @param {*} store
 * @returns
 */
export function waitUntilOffersReady(store) {
  const [waitForSetEnv, resolveEnv] = waitFor();
  const [waitForMerchantId, resolveMerchantId] = waitFor();
  const [waitForSessionId, resolveSessionId] = waitFor();

  waitForMerchantId.then(merchantId => {
    const { sessionId } = store.getState();
    if (!sessionId || (merchantId && !sessionId.startsWith(merchantId))) {
      store.dispatch(createSessionId(merchantId));
    } else {
      resolveSessionId(sessionId);
    }
  });

  const waitForReady = Promise.all([waitForMerchantId, waitForSetEnv, waitForSessionId]);

  waitForReady.then(() => {
    window.addEventListener('storage', listenLocalStorageChanges(store));
    if (!store.getState().auth?.ts) {
      store.dispatch(fetchAuth());
    }
  });

  return next => async action => {
    if (
      SET_ENVIRONMENT_LOCAL === action.type ||
      SET_ENVIRONMENT_DEV === action.type ||
      SET_ENVIRONMENT_STAGING === action.type ||
      SET_ENVIRONMENT_PROD === action.type
    ) {
      resolveEnv(action.payload);
    } else if (SET_MERCHANT_ID === action.type) {
      resolveMerchantId(action.payload);
    } else if (CREATED_SESSION_ID === action.type) {
      resolveSessionId(action.payload);
    } else {
      await waitForReady;
    }
    next(action);
  };
}
